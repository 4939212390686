/* You can add global styles to this file, and also import other style files */
//@import "./assets/css/common.sass";
//@import "./assets/css/custom.sass";
@import "~ui-elements/assets/common";
@import "~ui-elements/assets/custom";
//@import "./assets/fonts/style.css";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "~primeicons/primeicons.css";
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~ui-elements/assets/video-js.css';

html {
  scroll-behavior: smooth;
}

::ng-deep .cdk-overlay-dark-backdrop {
  background: #000;
}


::ng-deep .cdk-overlay-backdrop-showing {
  opacity: 0.8;
}

.example-container {
  width: 100%;
  margin-top: 20px;
  position: relative;
}

#detach-button-host {
  display: none !important; //STREAM-2236 opera pop-up button disable
}

.card-content {
  padding: 20px;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

ano-button {
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    margin-right: 10px;
  }
}

.flex-col-center {
  display: flex;
  align-items: center;
}

.heidelpaySandboxNotify {
  display: none;
}

.back-link {
  font-family: "Poppins-Regular", sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-right: 20px;
  vertical-align: middle;
  align-self: center;
}

ano-button.app-button-start {
  justify-content: flex-start;

  button {
    width: fit-content;
  }
}

.overflow-scroll {
  overflow: scroll !important;
}


.cursor-pointer {
  cursor: pointer !important;
}

ano-drag-and-drop {
  display: block;
  width: 100%;
  height: 100%;
}

.bg-color-orange {
  background-color: orange !important
}

.bg-color-blue {
  background-color: #0e54a9 !important
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.chat-full-screen {
  overflow: hidden;
}

.alert-info-box {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #ffeeba;
  border-radius: .25rem;
  color: #856404;
  background-color: #fff3cd;
  width: fit-content;
}

.alert-info-message {
  color: #0c5460 !important;
  background-color: #d1ecf1 !important;
  border-color: #bee5eb !important;
}

.blue-theme-filter > form {
  display: flex;
  z-index: 101;

  .dropdown-wrapper {
    max-width: 200px;
    width: 175px;

    .dropdown-item-select.form-control.no-border {
      height: auto !important;
    }

    .dropdown-item-select {
      font-size: 12px;
    }

    .select-dropdown {
      top: 100% !important;
    }

    .form-group-select {
      padding-right: 25px;
      background-color: #0e54a9 !important;
      color: white !important;

      &::placeholder {
        color: white !important;
      }

      &:after {
        right: 15px;
        zoom: 0.8;
        border-top-color: white !important;
      }
    }

    .caret {
      border-top: 7px solid #0c1d3f;
      z-index: 9999;
      border-top-color: #fff;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      display: inline-block;
      position: absolute;
      right: 15px;
      top: 50%;
      zoom: 0.8;
      transform: translateY(-50%);
    }

    .form-group-select:after {
      content: "";
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 7px solid #0c1d3f;
      display: inline-block;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    .dropdown-item-select:last-child {
      border-radius: 0 !important;
    }

    .form-control.form-group-select.placeholder {
      font-size: 12px !important;
      border: none;
      border-radius: 0 !important;
      z-index: 101;
    }

    .select-dropdown, .form-control.form-group-select {
      font-size: 12px !important;
      border-radius: 0 !important;
      max-height: 235px;
    }
  }

  .form-group-select {
    color: #0e54a9;
  }

  .form-group-select::after {
    border-top-color: #0e54a9;
  }

  //app-input {
  //  margin-right: 30px;
  //}
}

.owl-dt-container-buttons {
  display: none;
}

.dashboard-header-wrapper {
  display: flex;
  align-items: center;

  .alert {
    width: 98% !important;
    margin: 0 0 20px 15px;
  }
}

.empty-stream-list p, ano-loader.list-loader {
  width: 100%;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 30px;
}

//.public-page-suggested-media {
//
//  .area-stream-header {
//    min-height: 213px !important;
//    max-height: 213px !important;
//  }
//}

.card-livestreams .area-stream {
  margin-bottom: 30px !important;
}

.break-after:after {
  content: '\A';
  white-space: pre;
}

.change-forgotten-password-form, .new-password-form {
  .dropdown-box-body {
    padding: 0 20px;
  }

  .card {
    margin-bottom: 0 !important;
  }

  .bullet-list {
    margin-bottom: 35px;
  }
}

.icon {
  &-round {
    position: relative;
    display: flex;
    width: 30px;
    height: 30px;
    font-size: 20px;
    border: 2px solid;
    border-radius: 50%;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.with-tooltip {
  &:hover {
    [data-tooltip] {
      display: block;
    }
  }

  [data-tooltip] {
    display: none;

    &:before {
      content: attr(data-tooltip);
      position: absolute;
      top: 35px;
      left: -4px;
      padding: 5px 15px;
      font-size: 12px;
      line-height: 14px;
      font-family: "Poppins-Regular", sans-serif;
      background: #25252570;
      border-radius: 3px;
      white-space: nowrap;
    }

    &:after {
      content: '';
      border: 5px solid transparent;
      border-bottom: 5px solid #25252570;
      display: block;
      top: 25px;
      left: 8px;
      position: absolute;
    }
  }
}

svg .grid-line line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}
svg .grid-line path {
  stroke-width: 0;
}

svg .domain {
  stroke: none;
}
.table-actions-list, .actions {

  .icon.icon-edit {
    margin-right: 4px;
  }

  .icon.icon-download {
    margin: 0 4px;
  }
}

.form-check-label {
  cursor: pointer;
}

p.text-course {
  position: absolute;
  margin: 0;
  width: 100%;
  padding: 10px;
  opacity: 1;
  z-index: 2;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 140px;
}

.bottom-overlay, .bottom-course {
  .text-course {
    text-align: left;
    align-items: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 352px;
    height: 56px;
    top: 6px;
    padding: 10px;
  }

}

.btn-still {
  position: absolute;
  right: 18px;
  top: 18px;
  font-size: 10px;
}

.left-overlay, .right-overlay, .left-course, .right-course {
  .btn-still {
    right: 21px !important;
    left: 21px !important;
    top: auto !important;
    bottom: 15px !important;
  }
}

.link-color {
  color: $linkColor !important;

  &:hover {
    color: $linkLightColor !important;
  }
}

svg .axis-y .tick:first-child text{
  fill: none;
}

.embedded-mode {
  overflow: hidden;
}

::ng-deep .drag-scroll-content {
  height: 99.6%!important;
}

.mat-datepicker-content .mat-calendar-body-selected {
  background-color: #0e54a9 !important;
}

button:focus {
  outline: none;
}

.ano-month-picker {
  .mat-calendar-period-button {
    pointer-events: none;
  }
  .mat-calendar-arrow {
    display: none;
  }
}

.table-actions-list {
  position: relative;
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) {
  .card-livestreams .area-stream {
    margin-bottom: 20px !important;
  }

  .left-overlay, .right-overlay, .left-course, .right-course {
    .btn-still {
      right: 8px !important;
      left: 8px !important;
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}
